import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fixParentChildAsinIssue(params) {
    const result = await axios.put(endpoints.maintenance.Controller.fixParentChildAsinIssue(params))
    return result
  },
  async fetchInventorySummaryForStore(storeId) {
    const result = await axios.put(endpoints.maintenance.Controller.fetchInventorySummaryForStore(storeId))
    return result
  },
  async fetchMissingAmazonCatalogItems(storeId) {
    const result = await axios.put(endpoints.maintenance.Controller.fetchMissingAmazonCatalogItems(storeId))
    return result
  },
  async updateProductsFromAmazonData(skip) {
    const result = await axios.put(endpoints.maintenance.Controller.updateProductsFromAmazonData(skip))
    return result
  },
  async updateProductsWithMissingImages() {
    const result = await axios.put(endpoints.maintenance.Controller.updateProductsWithMissingImages())
    return result
  },
  async cleanInventory(accountNo) {
    const result = await axios.put(endpoints.maintenance.Controller.cleanInventory(accountNo))
    return result
  },
  async mergeDuplicatedMsku() {
    const result = await axios.put(endpoints.maintenance.Controller.mergeDuplicatedMsku())
    return result
  },
  async mergeMskusWithHashCode() {
    const result = await axios.put(endpoints.maintenance.Controller.mergeMskusWithHashCode())
    return result
  },
  async mergeDuplicatedFnsku() {
    const result = await axios.put(endpoints.maintenance.Controller.mergeDuplicatedFnsku())
    return result
  },
  async mergeDuplicatedProducts() {
    const result = await axios.put(endpoints.maintenance.Controller.mergeDuplicatedProducts())
    return result
  },
  async closeInboundBatches(data) {
    const result = await axios.put(endpoints.maintenance.Controller.closeInboundBatches(), data)
    return result
  },
  async fillEmptyUpcFromAmazon(storeId) {
    const result = await axios.put(endpoints.maintenance.Controller.fillEmptyUpcFromAmazon(storeId))
    return result
  },
  async getCatalogItemsByAsin(asin) {
    const result = await axios.get(endpoints.externalAmazon.controller.getCatalogItemByAsin(asin))
    return result
  },
}
