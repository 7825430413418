<template>
  <div>
    <div class="container-fluid card">
      <div class="card-body py-1">
        <div class="row py-1">
          <div class="col-12 px-0">
            <div class="text-right px-2">
              <dx-util-button-group key-expr="text" :items="granularities" :selected-item-keys="selectedKey" @item-click="getOrderMetricsDataByGranularity" />
            </div>
          </div>
          <div v-if="showProductCard" class="col-12 px-0">
            <div class="d-flex p-1">
              <img v-if="imageUrl" :src="imageUrl" class="img-fluid rounded text-white square m-half" style="height:10rem;width:10rem">
              <div class="m-half col-10 col-md-8">
                <h4 class="col-10">
                  <span v-if="selectedProductId" class="dx-product-title text-info" role="button" @click="openProductDetails">
                      {{ productTitle }}
                  </span>
                  <span v-else class="text-warning">
                    {{ productTitle }}
                  </span>

                </h4>
                <div v-if="upc" class="d-flex">
                  <div class="col-2 ">
                    <strong>
                      UPC
                    </strong>
                  </div>
                  <div>
                    {{ upc }}
                  </div>
                </div>
                <div v-if="ranking" class="d-flex">
                  <div class="col-2">
                    <strong>
                      Ranking
                    </strong>
                  </div>
                  <div class="">
                    {{ ranking }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showProductCard" class="col-12 px-0">
            <DxChart
              id="chart"
              :customize-label="customizeLabel"
              :data-source="chartData"
              default-pane="bottomPane"
            >
            <DxCommonSeriesSettings argument-field="interval" />
            <DxSeries
              pane="topPane"
              axis="sales"
              argument-field="interval"
              value-field="sales"
              name="Total Sales"
              type="bar"
              color="#75C199"
            />
            <DxSeries
              axis="unitCount"
              argument-field="interval"
              value-field="unitCount"
              name="Unit Count"
              type="bar"
              color="#1EB2F6"
            />
            <DxSeries
              axis="price"
              argument-field="interval"
              type="spline"
              value-field="price"
              name="Average Price"
              color="#F77F00"
            />
            <DxValueAxis name="sales" pane="topPane">
              <DxTitle text="Total Sales">
                <DxFont color="#75C199" />
              </DxTitle>
              <DxLabel :customize-text="customizeText">
                <DxFont color="#75C199" />
              </DxLabel>

            </DxValueAxis>
            <DxValueAxis name="unitCount" pane="bottomPane">
              <DxTitle text="Unit Count">
                <DxFont color="#1EB2F6" />
              </DxTitle>
              <DxLabel :customize-text="customizeText">
                <DxFont color="#1EB2F6" />
              </DxLabel>

            </DxValueAxis>
            <DxValueAxis
              name="price"
              position="right"
              pane="bottomPane"
            >
              <DxTitle text="Average Price, $">
                <DxFont color="#F77F00" />
              </DxTitle>
              <DxLabel>
                <DxFont color="#F77F00" />
              </DxLabel>
            </DxValueAxis>
            <DxTitle text="Order Metrics" />
            <DxSize :height="600" />
            <DxPane name="topPane" />
            <DxPane name="bottomPane" />
            </DxChart>
          </div>
          <div v-if="(!showProductCard && orderMetrics.asin !== '')" class="text-warning text-center">
            Please check the form data.
          </div>
        </div>
        <!--Begin:: Product Details -->
        <product-details :product-id="selectedProductId"
          :show-product="isVisible"
          @close="isVisible=false"
        />
        <!--End:: Product Details -->
      </div>
    </div>
    <div class="col-md-12 py-1 position-absolute fixed-bottom">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <p-icon name="bi-info-circle-fill" color="warning" size="32px" class="mr-1" />
        <span><strong>Beta Version: This is a beta version of this module. It is currently being tested and its usage is for free. Some features may be limited upon the full version release and will be subject to additional charges.</strong></span>
      </div>
    </div>
  </div>
</template>

<script>
import DxChart, {
  DxCommonSeriesSettings,
  DxSeries,
  DxValueAxis,
  DxLabel,
  DxTitle,
  DxFont,
  DxPane,
  DxSize,
} from 'devextreme-vue/chart'
import Filters from '@robustshell/mixins/filters'
import granularityEnum, { getGranularityList } from '@/enums/granularity.enum '
import fulfillmentNetworkEnum, { getList } from '@/enums/fulfillmentNetwork.enum'
import buyerTypeEnum, { getBuyerTypeList } from '@/enums/buyerType.enum'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import productsService from '@/http/requests/product/productsService'
import maintenanceService from '@/http/requests/common/maintenanceService'
import useCurrentUser from '@/libs/app/current-user'
import { Notify } from '@robustshell/utils/index'
import { GranularityEnum } from '@/enums'

export default {
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxLabel,
    DxTitle,
    DxFont,
    DxPane,
    DxSize,
    'product-details': () => import('@/views/apps/product/products/components/ProductDetails.vue'),
  },
  mixins: [Filters],
  props: {
    asin: {
      type: String,
      default: '',
    },
    storeId: {
      type: Number,
      default: 0,
    },
    productId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const oneYearAgo = new Date()
    const now = new Date()
    oneYearAgo.setMonth(oneYearAgo.getMonth() - 24)
    oneYearAgo.setHours(0, 0, 0, 1)
    now.setHours(23, 59, 59)
    return {
      orderMetrics: {
        interval: '',
        granularity: granularityEnum.MONTH.value,
        granularityTimeZone: null,
        buyerType: buyerTypeEnum.ALL.value,
        fulfillmentNetwork: fulfillmentNetworkEnum.ALL.value,
        firstDayOfWeek: 'sunday',
        asin: '',
        sku: null,
      },
      selectedKey: [GranularityEnum.MONTH.value],
      chartData: [],
      granularities: getGranularityList(),
      buyerTypes: getBuyerTypeList(),
      fulfillmentNetworks: getList(),
      endDate: now,
      beginDate: oneYearAgo,
      dateValidationMsg: 'Time range can not exceed 2 years',
      imageUrl: '',
      productTitle: '',
      ranking: null,
      upc: null,
      selectedProductId: null,
      isVisible: false,
      showProductCard: false,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  watch: {
    asin: {
      immediate: true,
      handler(newValue) {
        if (newValue !== '') {
          this.orderMetrics.asin = newValue
          this.$nextTick(() => {
            this.getOrderMetricsDataByGranularity({ itemData: { value: GranularityEnum.MONTH.value } })
          })
        }
      },
    },
  },
  setup() {
    const {
      userCompanyId,
    } = useCurrentUser()
    return {
      userCompanyId,
    }
  },
  created() {
    this.getStores()
  },
  mounted() {
    this.produceIntervalString()
  },
  methods: {
    customizeLabel(e) {
      if (e.value) {
        return {
          visible: true,
          backgroundColor: this.setLabelColor(e.seriesName),
          font: {
            color: this.setFontColor(e.seriesName),
          },
          customizeText({ valueText }) {
            return e.seriesName === 'Unit Count' ? `${valueText}` : `$${valueText}`
          },
        }
      }
      return null
    },
    customizeText({ valueText }) {
      return `${valueText}`
    },
    setLabelColor(seriesName) {
      if (seriesName === 'Unit Count') return '#8ED6FF'
      if (seriesName === 'Average Price') return '#FFA64D'
      if (seriesName === 'Total Sales') return '#BFFFCF'
      return '#000'
    },
    setFontColor(seriesName) {
      if (seriesName === 'Unit Count') return '#0C6C9F'
      if (seriesName === 'Average Price') return '#A25D00'
      if (seriesName === 'Total Sales') return '#4D8B6B'
      return '#FFF'
    },
    getOrderMetricsDataByGranularity(e) {
      this.orderMetrics.asin = this.asin
      this.orderMetrics.granularity = e.itemData.value
      this.selectedKey.length = 0
      this.selectedKey = [e.itemData.value]
      this.getCatalogItemByAsin()
      amazonReportService.getOrderMetrics(this.storeId, this.orderMetrics).then(res => {
        const { data } = res
        this.chartData = data.map(item => ({
          ...item,
          interval: this.prepareIntervalByGranularity(item?.interval),
          price: Number(item?.averageUnitPrice?.amount),
          sales: Number(item?.totalSales?.amount),
        }))
      }).catch(error => {
        Notify.error(`Data fetch failed: ${error}`)
      })
    },
    prepareIntervalByGranularity(interval) {
      let formattedInterval = interval
      switch (this.orderMetrics.granularity) {
        case granularityEnum.MONTH.value:
          formattedInterval = `${interval.substring(2, 4)}/${interval.substring(5, 7)}`
          break
        case granularityEnum.YEAR.value:
          formattedInterval = interval.substring(0, 4)
          break
        case granularityEnum.TOTAL.value:
          formattedInterval = interval.substring(0, 8)
          break
        default:
          break
      }
      return formattedInterval
    },
    produceIntervalString() {
      const begin = this.beginDate.toISOString().split('.')[0]
      const end = this.endDate.toISOString().split('.')[0]
      Object.assign(this.orderMetrics, { interval: `${begin}+00:00--${end}+00:00` })
    },
    openProductDetails() {
      this.isVisible = true
    },
    async getProductId() {
      const companyId = this.stores && this.stores.length > 1 ? this.stores[1]?.companyId : this.userCompanyId
      const result = await productsService.getProductBundleInfo(companyId, this.orderMetrics.asin)
      this.selectedProductId = result?.id
    },
    getCatalogItemByAsin() {
      maintenanceService.getCatalogItemsByAsin(this.orderMetrics.asin).then(res => {
        if (this.productId !== 0) {
          this.selectedProductId = this.productId
        } else {
          this.getProductId()
        }
        this.showProductCard = true
        this.catalogItem = res.data.body
        if (this.catalogItem === undefined || this.catalogItem === null) {
          return
        }
        this.imageUrl = this.catalogItem?.images[0]?.images[0]?.link
        this.productTitle = this.catalogItem?.summaries[0]?.itemName

        // Ranking selection
        const combinedData = []
        if (this.catalogItem.salesRanks !== null
          && this.catalogItem.salesRanks.length > 0
          && this.catalogItem.salesRanks[0] !== null
          && this.catalogItem.salesRanks[0].classificationRanks !== null
          && this.catalogItem.salesRanks[0].classificationRanks.length > 0) {
          const classificationRank = this.catalogItem.salesRanks[0].classificationRanks.map(item => ({ title: item.title, rank: item.rank }))
          combinedData.push(...classificationRank)
        }
        if (this.catalogItem.salesRanks !== null
          && this.catalogItem.salesRanks.length > 0
          && this.catalogItem.salesRanks[0] !== null
          && this.catalogItem.salesRanks[0].displayGroupRanks !== null
          && this.catalogItem.salesRanks[0].displayGroupRanks.length > 0) {
          const displayGroupRank = this.catalogItem.salesRanks[0].displayGroupRanks.map(item => ({ title: item.title, rank: item.rank }))
          combinedData.push(...displayGroupRank)
        }
        if (combinedData && combinedData.length > 0) {
          const rankingObj = combinedData.reduce((prev, current) => ((prev.rank < current.rank) ? prev : current))
          this.ranking = `#${rankingObj.rank} in ${rankingObj.title}`
        }
        let identifiers = this.catalogItem.identifiers[0]?.identifiers
        if (identifiers && identifiers.length > 0) {
          identifiers = identifiers.filter(el => el.identifierType === 'UPC' || el.identifierType === 'EAN' || el.identifierType === 'GTIN')
          this.upc = identifiers[0].identifier
        }
      }).catch(error => {
        this.showProductCard = false
        Notify.error(`Product fetch by ASIN failed: ${error}`)
      })
    },
  },
}
</script>
<style scoped>
</style>
