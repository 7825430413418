import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  getRestockRecommendations(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.amazonReports.Controller.getRestockRecommendations(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  getOrderMetrics(storeId, bodyData) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.amazonReports.Controller.getOrderMetrics(storeId), bodyData)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  getMonthlySales(pageable, bodyData) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.amazonReports.Controller.getMonthlySales(pageable), bodyData)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  getInventoryHistoricalValues(bodyData, pageable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.amazonReports.Controller.getInventoryHistoricalValues(pageable), bodyData)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async getMonthlySalesSummary(pageable, data) {
    const result = await axios.post(endpoints.amazonReports.Controller.getMonthlySalesSummary(pageable), data)
    return result
  },
  async createMonthlySalesSummary(data) {
    const result = await axios.post(endpoints.amazonReports.Controller.createMonthlySalesSummary(), data)
    return result
  },
  async getItemsByMonthId(pageable, monthId) {
    const result = await axios.post(endpoints.amazonReports.Controller.getItemsByMonthId(monthId, pageable))
    return result
  },
  async updateItemCost(data) {
    const result = await axios.put(endpoints.amazonReports.Controller.updateItemCost(), data)
    return result
  },
  async deleteSalesSummary(id) {
    const result = await axios.delete(endpoints.amazonReports.Controller.deleteSalesSummary(id))
    return result
  },
  async getInboundItemCostSales(pageable, data) {
    const result = await axios.post(endpoints.amazonReports.Stats.getInboundItemCostSales(pageable), data)
    return result.data.body
  },
  async getAmazonReport(pageable, data) {
    if (!data) return null
    const result = await axios.post(endpoints.amazonReports.Controller.getAmazonReport(pageable), data)
    return result.data.body
  },
  async getAmazonMonthlyReport(data) {
    const result = await axios.post(endpoints.amazonReports.Controller.getAmazonMonthlyReport(), data)
    return result.data.body
  },
  async getAmazonSumReport(data) {
    const result = await axios.post(endpoints.amazonReports.Controller.getAmazonSumReport(), data)
    return result.data.body
  },
  async getAmazonSupplierReport(data) {
    const result = await axios.post(endpoints.amazonReports.Controller.getAmazonSupplierReport(), data)
    return result.data.body
  },
  async salesByQuery(pageable, data) {
    const result = await axios.post(endpoints.amazonReports.Controller.salesByQuery(pageable), data)
    return result.data.body
  },
  async returnsByQuery(pageable, data) {
    const result = await axios.post(endpoints.amazonReports.Controller.returnsByQuery(pageable), data)
    return result.data.body
  },
  async dashboardDataByQuery(data) {
    const result = await axios.post(endpoints.amazonReports.Controller.dashboardDataByQuery(), data)
    return result.data.body
  },
  async dashboardComparison(data) {
    const result = await axios.post(endpoints.amazonReports.Controller.dashboardComparison(), data)
    return result.data.body
  },
  async dashboardSales(data) {
    const result = await axios.post(endpoints.amazonReports.Controller.dashboardSales(), data)
    return result.data.body
  },
  async dashboardIncomeStatement(data) {
    const result = await axios.post(endpoints.amazonReports.Controller.dashboardIncomeStatement(), data)
    return result.data.body
  },
  async reimbursementsByQuery(pageable, data) {
    const result = await axios.post(endpoints.amazonReports.Controller.reimbursementsByQuery(pageable), data)
    return result.data.body
  },
  async removalsByQuery(pageable, data) {
    const result = await axios.post(endpoints.amazonReports.Controller.removalsByQuery(pageable), data)
    return result.data.body
  },
  async createRemoval(data) {
    const result = await axios.post(endpoints.amazonReports.Controller.createRemoval(), data)
    return result.data.body
  },
  async updateRemovalQuantity(itemId, quantity) {
    const result = await axios.put(endpoints.amazonReports.Controller.updateRemovalQuantity(itemId, quantity))
    return result.data.body
  },
  async getInventoryByMsku(storeId, msku) {
    const result = await axios.get(endpoints.amazonReports.Controller.getInventoryByMsku(storeId, msku))
    return result.data.body
  },
  async deleteAmazonListing(storeId, msku) {
    const result = await axios.get(endpoints.amazonReports.Controller.deleteAmazonListing(storeId, msku))
    return result.data.body
  },
  async updateAmazonOnHandInventory(storeId, msku, quantity) {
    const result = await axios.put(endpoints.amazonReports.Controller.updateAmazonOnHandInventory(storeId, msku, quantity))
    return result.data.body
  },
  async updateBuyCost(data) {
    const result = await axios.put(endpoints.amazonReports.Controller.updateBuyCost(), data)
    return result.data.body
  },
  async downloadTemplate() {
    const result = await axios.get(endpoints.amazonReports.Download.downloadTemplate())
    return result.data
  },
}
