import commonEntityService from '@/http/requests/common/commonEntityService'
import shipService from '@/http/requests/ship/shipService'

export default {
  data() {
    return {
      accountNo: null,
      warehouses: [],
      selectedWarehouse: 0,
      stores: [],
      selectedStore: 0,
    }
  },
  methods: {
    getWarehouses() {
      shipService.fetchWarehouses().then(result => {
        const data = result.data
        this.setWarehouse(data)
      })
    },
    setWarehouse(data) {
      this.warehouses.splice(0, this.warehouses.length)
      this.warehouses.push({ value: 0, text: 'Warehouse (All)' })
      data.forEach(element => {
        if (element.name.toLowerCase() !== 'common') {
          this.warehouses.push({ value: element.id, text: element.name })
        }
      })
    },
    getStoresByParentCompanyAccountNo(accountNo) {
      commonEntityService
        .fetchStoresByParentCompanyAccountNo(accountNo)
        .then(result => {
          const data = result.data
          this.setStores(data)
        })
    },
    getStoresForCurrentCompany() {
      commonEntityService.fetchStoresForCurrentCompany().then(result => {
        const data = result.data
        this.setStores(data)
      })
    },
    getStores(accountNo) {
      if (accountNo) {
        this.getStoresByParentCompanyAccountNo(accountNo)
      } else {
        this.getStoresForCurrentCompany()
      }
    },
    setStores(data) {
      this.stores.splice(0, this.stores.length)
      this.stores.push({
        value: 0,
        text: 'Store (All)',
      })
      data.forEach(element => {
        this.stores.push({
          value: element.id,
          text: element.name,
          companyId: element.parentCompany,
        })
      })
      if (this.stores.length > 0) {
        this.selectedStore = this.stores[0].value
      }
    },
  },
}
